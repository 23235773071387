import React from 'react';
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useState } from 'react';
import logo from '../assets/logo.png'
export default function Navbar() {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  return (
    <div className="w-full lg:h-[80px] h-[50px] flex justify-between items-center px-4 bg-gradient-to-tr from-slate-300 via-slate-200 to-slate-200 text-[#FFAA1F]  fixed z-10">
        <Link to='home' className='hover:cursor-pointer'  smooth={true} duration={500}>
            <div className=' flex items-center'>
                    <p className=' bg-red-300 lg:m-5'>
                        <img src={logo} className=' w-full lg:h-[80px] h-[50px]' />
                    </p>
                    <h1 className="text-3xl text-[#02CEE3] bg-[#CAE65B] p-2 hidden lg:block rounded-lg shadow font-bold">PREMANKUR</h1>
            </div>
        </Link>

      {/* Desktop Menu */}
      <ul className="hidden md:flex text-2xl space-x-8">
        <li>
          <Link to="home" className=' hover:text-yellow-600 hover:cursor-pointer hover:border  hover:border-yellow-500 rounded-lg hover:bg-gradient-to-tr hover:from-slate-400 hover:via-slate-500 hover:to-slate-500 p-4' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link to="about" className=' hover:text-yellow-600 hover:cursor-pointer hover:border  hover:border-yellow-500 rounded-lg hover:bg-gradient-to-tr hover:from-slate-400 hover:via-slate-500 hover:to-slate-500 p-4' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li>
          <Link to="projects" className=' hover:text-yellow-600 hover:cursor-pointer hover:border  hover:border-yellow-500 rounded-lg hover:bg-gradient-to-tr hover:from-slate-400 hover:via-slate-500 hover:to-slate-500 p-4' smooth={true} duration={500}>
            Projects
          </Link>
        </li>
        <li>
          <Link to="contact" className=' hover:text-yellow-600 hover:cursor-pointer hover:border  hover:border-yellow-500 rounded-lg hover:bg-gradient-to-tr hover:from-slate-400 hover:via-slate-500 hover:to-slate-500 p-4' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger Menu for Mobile */}
      <div onClick={handleClick} className="md:hidden z-10 text-4xl">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen text-yellow-200 bg-slate-600 flex flex-col justify-center items-center'
        }
      >
        <li className="py-6 text-2xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-2xl">
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-2xl">
          <Link onClick={handleClick} to="projects" smooth={true} duration={500}>
            Projects
          </Link>
        </li>
        <li className="py-6 text-2xl">
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
}
