import React, { useState } from 'react';

import vdo1 from '../assets/videos/1.mp4';
import vdo2 from '../assets/videos/2.mp4';
import vdo3 from '../assets/videos/3.mp4';
import vdo4 from '../assets/videos/4.mp4';
import vdo5 from '../assets/videos/5.mp4';
import img1 from '../assets/1.png';
import img2 from '../assets/2.png';
import img3 from '../assets/3.png';
import img4 from '../assets/4.png';
import img5 from '../assets/5.png';
import img6 from '../assets/6.png';
import img7 from '../assets/7.png';
import img8 from '../assets/8.png';
import img9 from '../assets/9.png';
import img10 from '../assets/10.png';
import img11 from '../assets/11.png';
import img12 from '../assets/12.png';
import img13 from '../assets/13.png';
import img14 from '../assets/14.png';
import img15 from '../assets/15.png';
import img16 from '../assets/16.png';
import img17 from '../assets/17.png';
import img18 from '../assets/18.png';
import img19 from '../assets/19.png';
import img20 from '../assets/20.png';
import img21 from '../assets/21.png';
import img22 from '../assets/22.png';
import img23 from '../assets/23.png';
import img24 from '../assets/24.png';
import img25 from '../assets/25.png';

// Example media data, you can replace this with your actual media list
const mediaItems = [
  { id: 1, type: 'image', src: img1, title: 'Image 1' },
  { id: 2, type: 'video', src: vdo1, title: 'Video 1' },
  { id: 3, type: 'image', src: img2, title: 'Image 2' },
  { id: 4, type: 'video', src: vdo2, title: 'Video 2' },
  { id: 5, type: 'video', src: vdo3, title: 'Video 3' },
  { id: 6, type: 'video', src: vdo4, title: 'Video 4' },
  { id: 7, type: 'video', src: vdo5, title: 'Video 5' },
  { id: 8, type: 'image', src: img3, title: 'Image 3' },
  { id: 9, type: 'image', src: img4, title: 'Image 4' },
  { id: 10, type: 'image', src: img5, title: 'Image 5' },
  { id: 11, type: 'image', src: img6, title: 'Image 6' },
  { id: 12, type: 'image', src: img7, title: 'Image 7' },
  { id: 13, type: 'image', src: img8, title: 'Image 8' },
  { id: 14, type: 'image', src: img9, title: 'Image 9' },
  { id: 15, type: 'image', src: img10, title: 'Image 10' },
  { id: 16, type: 'image', src: img11, title: 'Image 11' },
  { id: 17, type: 'image', src: img12, title: 'Image 12' },
  { id: 18, type: 'image', src: img13, title: 'Image 13' },
  { id: 19, type: 'image', src: img14, title: 'Image 14' },
  { id: 20, type: 'image', src: img15, title: 'Image 15' },
  { id: 21, type: 'image', src: img16, title: 'Image 16' },
  { id: 22, type: 'image', src: img17, title: 'Image 17' },
  { id: 23, type: 'image', src: img18, title: 'Image 18' },
  { id: 24, type: 'image', src: img19, title: 'Image 19' },
  { id: 25, type: 'image', src: img20, title: 'Image 20' },
  { id: 26, type: 'image', src: img21, title: 'Image 21' },
  { id: 27, type: 'image', src: img22, title: 'Image 22' },
  { id: 28, type: 'image', src: img23, title: 'Image 23' },
  { id: 29, type: 'image', src: img24, title: 'Image 24' },
  { id: 30, type: 'image', src: img25, title: 'Image 25' },
];

export default function Gallery() {
  const [selectedMedia, setSelectedMedia] = useState(mediaItems[0]);

  return (
    <div className='flex flex-col w-full max-h-150 overflow-auto'>
      {/* Media Viewer */}
      <div className='flex-1 bg-gray-100 p-4 max-h-96 overflow-auto'>
        {selectedMedia.type === 'image' ? (
          <img src={selectedMedia.src} alt={selectedMedia.title} className='w-full h-full object-contain' />
        ) : (
          <video src={selectedMedia.src} controls className='w-full h-full object-contain' />
        )}
      </div>

      {/* Media List */}
      <div className='bg-gray-200 overflow-x-auto'>
        <h2 className='text-xl font-bold mb-4'>Media List</h2>
        <div className='flex space-x-4'>
          {mediaItems.map((item) => (
            <div
              key={item.id}
              onClick={() => setSelectedMedia(item)}
              className='cursor-pointer mb-2 hover:bg-gray-300 rounded flex-shrink-0'
            >
              {item.type === 'image' ? (
                <img src={item.src} alt={item.title} className='w-24 h-24 object-cover' />
              ) : (
                <video src={item.src} className='w-24 h-24 object-cover' />
              )}
              <span className='block mt-2 text-center'>{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
