import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import logolarge  from '../assets/logo-large.png'
import ImageSlider from './ImageSlider';
import CollapsibleSection from './CollapsibleSection';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { PiCertificateFill } from "react-icons/pi";
import PDFViewer from './PDFViewer';
import Modal from './Modal';
import vdo1 from '../assets/videos/1.mp4'
import vdo2 from '../assets/videos/2.mp4'
import Gallery from './Gallery';
export default function HomePage() {
    const [showModal, setShowModal] = useState(false);
    const [element, setElement] = useState(null)

    useEffect(() => {
      const handleScroll = () => {
        const sections = document.querySelectorAll('.parallax');
        sections.forEach((section) => {
          const offset = window.scrollY;
          section.style.backgroundPositionY = `${offset * 0.5}px`;
        });
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const openModal = () => {
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };

  return (
    <div>
        <Navbar />
        <div className='fixed z-50 lg:bottom-10 lg:right-10 bottom-5 right-5'>
            <button 
            onClick={()=>{openModal(); setElement(<PDFViewer/>)}} 
            className='flex items-center bg-gradient-to-tr from-[#CAE65B] via-lime-300 to-lime-200 text-[#a27328fb] p-4 hover:p-5 lg:text-3xl shadow-lg rounded-xl justify-center'
            >
            <PiCertificateFill className='lg:text-4xl' />
            <span className='m-2 font-semibold'>Certifications</span>
            </button>
        </div>
        <div className="lg:pt-[80px]pt-[50px] bg-gradient-to-tr from-slate-50 via-slate-50 to-white">
        <section
          id="home"
          className="parallax h-screen flex justify-center flex-wrap items-center text-4xl bg-gradient-to-tr from-lime-200 via-lime-100 to-yellow-100"
          style={{ backgroundAttachment: 'fixed' }}
        >
            <div className=' w-full'>
                <div className='w-full  max-h-1/2'>
                    <div className=' w-full flex items-center justify-center '>
                        <img src={logolarge}></img>
                    </div>
                    <div className='w-full text-5xl font-bold mt-5 flex items-center justify-center'>
                        <h1 className=' text-center'>PREMANKUR SAMAJIK SANSTHA</h1>
                    </div>
                </div>
                <div className=' w-full p-10 max-h-1/2 '>
                    <p className=' text-center w-full text-base'>
                        Enhancing livelihood security of the marginalised, especially the tribal’s in 50 villages of Nashik district
                    </p>
                </div>
                <div className='flex items-center justify-center p-2'>
                  <table className=' text-base text-center lg:w-1/2 w-full'>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>
                        Date & Year of Establishment of NGO (Registration No.) {`Trust/Society`}
                      </td>
                      <td>
                        MAHARASHTRA / 8039 /2003 DATE 22 APRIL 2003 
                        F – 7823 / 2003 DATE – 15 SEPT 2003 
                        SOCIETY 
                      </td>
                    </tr>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>
                        12A Registration
                      </td>
                      <td>
                        12AA/2009-10/70/93
                      </td>
                    </tr>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>80G with Validity period</td>
                      <td>80G/2010/4540 29/01/2010 </td>
                    </tr>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>PAN No</td>
                      <td>AAAAP6866A</td>
                    </tr>
                  </table>
                </div>
            </div>
        </section>

        <section
          id="about"
          className="parallax h-screen flex lg:pt-[80px] pt-[50px] flex-wrap justify-center items-center text-4xl overflow-auto"
          style={{ backgroundAttachment: 'fixed' }}
          >
            <div className=' w-full h-full'>
                <h2 className=' font-bold m-2'>
                    About
                </h2>
                <div className=' w-full sticky top-0'>
                    <ImageSlider/>
                </div>
                <div className=' mt-5 flex items-center p-2 justify-center'>
                  <table className=' text-base text-center lg:w-1/2 w-full'>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>
                        No of Trustee on Board
                      </td>
                      <td>07
                      </td>
                    </tr>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>
                        Are Trustee Related to Each Other
                      </td>
                      <td>
                        NO
                      </td>
                    </tr>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>No. of governing body meeting held a year</td>
                      <td>03</td>
                    </tr>
                    <tr className=' border border-yellow-600'>
                      <td className=' font-semibold'>Goal of The Organization</td>
                      <td>TO ESTABLISH SOCIAL, ECONOMIC AND POLITICAL JUSTICE FOR WOMEN THOUGHT FOR SUSTAINABLE DEVELOPMENT </td>
                    </tr>
                  </table>
                </div>
                <div className=' w-full text-base text-justify p-2 overflow-auto'>
                    <article>
                        With a strong belief that ‘People’s Participation’ plays the most beneficial role in achieving social changes for the development of any society, Premankur is working in tribal villages in Peth and Surgana Blocks. It is here that the projects like Panchayat Sakhi by Sakhi, Balmitra, Akar library, Media Resource Centre are implemented on to bring the people of the respective age groups together. The aim is to conserve the tradition of villages, strengthen the means of employment in villages, generate employment with respect to the needs of the villages and the urban areas, and ensure that the children get their right to education and development, increase awareness about bio-diversity in the surrounding areas.
                        <br/><br/>
                        The NGO also focuses on poverty removal programme in the urban areas, especially which is related to direct migration of people from the rural areas. Migration happens in search of an employment. With the help of Support Grahak Seva Kendra, the organization is trying to establish the manufacturing bases in the rural areas to help employment there. <br/> <b>Role of Premankur </b>- We first tried to understand the social and individual issues of the region and their basic needs. We tried to get to the roots of the basic question – What is development? Generally, development of materialistic things and facilities, but we are now witnessing the consequences of the same. We see two worlds in one - On one side handful of those who have plenty and on the other side millions of those who fight everyday for their daily bread. The form of development is only widening the gap between the two worlds. The rural populace’s access to better health, safe drinking water, education is growing difficult with every passing day. As if this was not enough the all-powerful society is further snatching away the means of rural populace’s livelihood under the guise of development – thus mauling rights of the individual to live and earn a livelihood.
                        <br/><br/>
                        This so-called development process, benefiting particular class, has to be demolished and it is possible only with the people’s participation. Premankur believes that this can be achieved only with the people’s participation in the public-oriented programmes drawn for the people and by the people. Based on this theme the organization focused on the four elements of a village children, youth, women and farmers and working for them increased its reach and scope for development as well. 
                    </article>
                </div>
            </div>
            {/* <PDFViewer/> */}
        </section>

        <section
          id="projects"
          className="parallax max-h-auto lg:pt-[80px] pt-[50px] flex flex-wrap justify-center items-center text-4xl overflow-auto"
          style={{ backgroundAttachment: 'fixed' }}
        >
          <div className='w-full h-full flex flex-wrap justify-center items-center'>
            <h2 className='font-bold m-2 w-full text-center'>Important Programmes</h2>
            <div className='w-full lg:w-1/2 p-4'>
              <CollapsibleSection />
            </div>
            <div className='w-full lg:w-1/2 p-4 flex flex-wrap items-center justify-center'>
              <video 
                src={vdo1} 
                controls
                className='w-full h-auto' 
                alt='Programmes Video'
              />
              <div className=' m-2'>
                <button className='w-full lg:w-auto bg-gradient-to-tr from-[#CAE65B] via-lime-300 to-lime-200 text-[#a27328fb] text-xl text-left p-3 rounded-xl shadow-md' 
                onClick={()=>{openModal(); setElement(<Gallery/>)}} >Gallery</button>
              </div>
            </div>
          </div>
        </section>


        <section id="contact" className="parallax h-screen bg-slate-500 flex justify-center items-center text-white text-xl" style={{ backgroundAttachment: 'fixed' }}>
            <div className="container mx-auto p-4">
            <h2 className="text-4xl font-bold text-center mb-10">Contact Us</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Column 1: Address */}
                <div className="flex flex-col items-center">
                <FaMapMarkerAlt className="text-6xl mb-4" />
                <h3 className="text-2xl font-semibold mb-2">Our Location</h3>
                <p className="text-center text-base">
                <b> Registered Address :</b><br/> 
                5, GURUVAIBHAV CO-OPP HOUSING SOCIETY<br/> 
                BEHIND K.J. MEHTA HIGH SCHOOL , 
                    <br /> Maharashtra, INDIA
                    <br/>
                    <br/>
                <b> Communication Address :</b><br/> 

                    4, MAA, KUMBHPUJA ROW HOUSE 
                    <br/>
                BEHIND K. J. MEHTA HIGH SCHOOL 
                <br />Sadguru Nagar, Nashik Road, Nashik. 422102
                    <br /> Maharashtra, INDIA
                </p>
                </div>

                {/* Column 2: Phone */}
                <div className="flex flex-col items-center">
                <FaPhone className="text-6xl mb-4" />
                <h3 className="text-2xl font-semibold mb-2">Call Us</h3>
                <p className="text-center">
                (0253) 2464713
                    <br /> +91 9822253518
                </p>
                </div>

                {/* Column 3: Email */}
                <div className="flex flex-col items-center">
                <FaEnvelope className="text-6xl mb-4" />
                <h3 className="text-2xl font-semibold mb-2">Email Us</h3>
                <p className="text-center">
                    mangesh229@gmail.com
                </p>
                </div>
            </div>
            </div>
        </section>
      </div>
      <Modal show={showModal} onClose={closeModal} children={element}/>
    </div>
  );
}
