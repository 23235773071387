import React from 'react';

export default function Modal({ show, onClose, children }) {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center overflow-auto justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full">
        <p className=' w-full text-right'>
          <button
            className=" font-bold text-xl text-red-500 hover:text-gray-700"
            onClick={onClose}
          >
            X
          </button>
        </p>
        
        {children}
      </div>
    </div>
  );
}
