import React, { useState } from 'react';
import pdf from '../assets/pdf/Approval Letter_231117_180552.pdf';
import pdf1 from '../assets/pdf/Premankur 12A80G Cert.pdf';
import pdf2 from '../assets/pdf/Premankur IT Ack 1112_231016_185954.pdf';
import pdf3 from '../assets/pdf/PREMANKUR SAMAJIK SANSTHA -  80G _231016_185405.pdf';
import pdf4 from '../assets/pdf/PREMANKUR SAMAJIK SANSTHA -  MEMORENDUN_231016_185432.pdf';
import pdf5 from '../assets/pdf/PREMANKUR SAMAJIK SANSTHA -  PUBLIC TRUST ACT REGI_231016_185505.pdf';
import pdf6 from '../assets/pdf/PREMANKUR SAMAJIK SANSTHA -  SOCIETY REGI_231016_185521.pdf';
import pdf7 from '../assets/pdf/Premankur Samajik Sanstha_240829_215027.pdf';
import pdf8 from '../assets/pdf/Concern india praposal draft.pdf';
import pdf9 from '../assets/pdf/Livelihood security proposal Premankur Jan 2011.pdf';
import pdf10 from '../assets/pdf/PSS - Yearly Report - 08 -  09_240829_213855.pdf';

const pdfList = [
  pdf, pdf1, pdf2, pdf3, pdf4, pdf5, pdf6, pdf7, pdf8, pdf9, pdf10
];

export default function PDFViewer() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : pdfList.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex < pdfList.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <div className="w-full h-150 overflow-auto flex flex-col items-center">
      <iframe
        src={pdfList[currentIndex]}
        width="100%"
        height="90%"
        title="PDF Viewer"
      ></iframe>
      <div className="flex justify-between w-full p-4">
        <button
          onClick={handlePrevious}
          className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
        >
          Next
        </button>
      </div>
    </div>
  );
}
